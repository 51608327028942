export const authLink = {
  development:
    "https://www.linkedin.com/uas/login?session_redirect=%2Foauth%2Fv2%2Flogin-success%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522r_liteprofile%2Br_emailaddress%2Bw_member_social%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1690900346256%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fdev.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D&fromSignIn=1&trk=oauth&cancel_redirect=%2Foauth%2Fv2%2Flogin-cancel%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522r_liteprofile%2Br_emailaddress%2Bw_member_social%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1690900346256%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fdev.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D",
  prestage:
    "https://www.linkedin.com/uas/login?session_redirect=%2Foauth%2Fv2%2Flogin-success%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522r_liteprofile%2Br_emailaddress%2Bw_member_social%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1690900346256%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fdev.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D&fromSignIn=1&trk=oauth&cancel_redirect=%2Foauth%2Fv2%2Flogin-cancel%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522r_liteprofile%2Br_emailaddress%2Bw_member_social%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1690900346256%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fdev.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D",
  staging:
    "https://www.linkedin.com/uas/login?session_redirect=%2Foauth%2Fv2%2Flogin-success%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522w_member_social%2Br_liteprofile%2Br_emailaddress%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1691591310364%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fstage.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D&fromSignIn=1&trk=oauth&cancel_redirect=%2Foauth%2Fv2%2Flogin-cancel%3Fapp_id%3D212131557%26auth_type%3DAC%26flow%3D%257B%2522scope%2522%253A%2522w_member_social%2Br_liteprofile%2Br_emailaddress%2522%252C%2522state%2522%253A%2522asdsad%2522%252C%2522currentStage%2522%253A%2522LOGIN_SUCCESS%2522%252C%2522currentSubStage%2522%253A0%252C%2522appId%2522%253A212131557%252C%2522authorizationType%2522%253A%2522OAUTH2_AUTHORIZATION_CODE%2522%252C%2522authFlowName%2522%253A%2522generic-permission-list%2522%252C%2522creationTime%2522%253A1691591310364%252C%2522redirectUri%2522%253A%2522https%253A%252F%252Fstage.api.cloud.picupmedia.com%252Fauth%252Flinkedin%252Fdashboard%2522%257D",
  production:
    "https://www.linkedin.com/oauth/v2/authorization?client_id=78xmeu75criyzb&redirect_uri=https://api.cloud.picupmedia.com/auth/linkedin/dashboard&state=asdsad&scope=scope=w_member_social%20r_liteprofile%20r_emailaddress&response_type=code",
};
